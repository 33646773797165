import { StaticImage } from "gatsby-plugin-image";
import React from "react";
import ContactForm from "../../components/contact-form";
import Layout from "../../components/layout";
import Seo from "../../components/seo"
import PortfolioFeed from "../../components/portfolio-feed";
import Quote from "../../components/quote";

const PortfolioHeader = () => (
  <section className="bg-gray-700 py-6">
    <div className="container">
      <div className="row">
        <div className="col-12 col-lg-4">
          <div className="row">
            <div className="col-12">
              <h3 className="text-secondary-light">Client</h3>
              <p className="text-white">Bundle Loan</p>
            </div>
            <div className="col-12 mt-5">
              <h3 className="text-secondary-light">Our Role</h3>
              <p className="text-white">UI/UX Services</p>
              <p className="text-white">Website Design Consulting</p>
              <p className="text-white">Logo & Identity Design</p>
              <p className="text-white">Custom Web Application Development</p>
              <p className="text-white">Payment Processor Integration</p>
            </div>
          </div>
        </div>
        <div className="col-12 col-lg-8 mt-5 mt-lg-0">
          <h3 className="text-secondary-light">Summary</h3>
          <h2 className="text-white">
            WE HELPED BUNDLE LOAN TURN AN IDEA INTO REALITY BY BUILDING AN
            EASY-TO-USE WEBSITE THAT BRINGS CLARITY TO THE MORTGAGE PURCHASING
            PROCESS.
          </h2>
        </div>
      </div>
    </div>
  </section>
);

const Sketches = () => (
  <section>
    <div className="container-fluid">
      <div className="row">
        <div className="col-12 p-0">
          <StaticImage
            style={{ width: "100%" }}
            src="../../images/portfolio/bundleloan/sketches.png"
            alt="Bundle logo sketches"
          />
        </div>
      </div>
    </div>
  </section>
);

const ProjectGoals = () => (
  <section className="bg-portfolio-light py-6">
    <div className="container">
      <div className="row">
        <div className="col-12 col-lg-6">
          <h2>Problem Statement</h2>
          <p>
            Bundle Loans needed to create a website that made it simple and easy
            for users to get a mortgage. They already had an idea, a handful of
            wireframes, many complicated calculations and a passionate CEO ready
            to dive in with both feet.
          </p>
          <p>
            We needed to take the lengthy mortgage buying process and make it
            accessible for multiple types of users. As a startup, the client
            also needed us to find room for growth opportunity in their business
            model.
          </p>
        </div>
        <div className="col-12 col-lg-6 mt-5 mt-lg-0">
          <h2>Project Goals</h2>
          <ol className="text-primary">
            <li>
              <span className="text-dark">
                Establish a logo and brand identity.
              </span>
            </li>
            <li>
              <span className="text-dark">
                Determine the full scope of the project.
              </span>
            </li>
            <li>
              <span className="text-dark">
                Phase the approach to accommodate the client’s budget.
              </span>
            </li>
            <li>
              <span className="text-dark">
                Design a UI and UX that simplifies the complex mortgage buying
                process.
              </span>
            </li>
            <li>
              <span className="text-dark">
                Develop a custom website that’s flexible enough for future
                growth.
              </span>
            </li>
            <li>
              <span className="text-dark">
                Integrate the website with a payment processor for monthly
                recurring subscriptions.
              </span>
            </li>
          </ol>
        </div>
      </div>
    </div>
  </section>
);

const BundleLogos = () => (
  <section>
    <div className="container-fluid px-0">
      <div className="row">
        <div className="col-12 p-0">
          <StaticImage
            style={{ width: "100%" }}
            src="../../images/portfolio/bundleloan/logos.png"
            alt="Bundle logos"
          />
        </div>
      </div>
    </div>
  </section>
);

const HowWeDidIt = () => (
  <section className="my-6">
    <div className="container">
      <div className="row">
        <div className="col-12 col-lg-6">
          <div className="row">
            <div className="col-12">
              <h2 className="mb-2">Development Languages Used</h2>
              <p className="mb-0">JavaScript</p>
              <p className="mb-0">Ruby</p>
              <p className="mb-0">HTML 5</p>
              <p className="mb-0">CSS 3</p>
            </div>
            <div className="col-12">
              <h2 className="mt-5">Tools</h2>
              <p className="mb-0">Docker</p>
              <p className="mb-0">Prometheus</p>
              <p className="mb-0">Segment.io</p>
              <p className="mb-0">Kubernetes</p>
              <p className="mb-0">SumoLogic </p>
            </div>
            <div className="col-12">
              <h2 className="mt-5">Frameworks Used</h2>
              <p className="mb-0">Ruby on Rails</p>
              <p className="mb-0">Bootstrap</p>
              <p className="mb-0">React.js</p>
            </div>
            <div className="col-12">
              <h2 className="mt-5">Databases</h2>
              <p className="mb-0">Databases</p>
            </div>
          </div>
        </div>
        <div className="col-12 col-lg-6">
          <div className="row">
            <div className="col-12 mb-5">
              <h2 className="mb-2 mt-5 mt-lg-0">Software Used</h2>
              <div className="row">
                <div className="col-9">
                  <div className="row">
                    <div className="col-2 pr-0">
                      <StaticImage
                        src="../../images/tools/adobe-illustrator.png"
                        alt="Adobe Illustrator"
                        width={50}
                        height={50}
                      />
                    </div>
                    <div className="col-2 pr-0">
                      <StaticImage
                        src="../../images/tools/adobe-photoshop.png"
                        alt="Adobe Photoshop"
                        width={50}
                        height={50}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12">
              <h2 className="mb-2">Research Methods</h2>
              <div className="row align-items-center">
                <div className="col-2">
                  <svg
                    color="gray"
                    id="ea8c11b2-3e00-4825-b150-55c04cc12fa5"
                    data-name="Layer 1"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 144 144"
                  >
                    <g
                      id="f1c4d81c-7f94-4ebe-9ccf-aae8eb9c4e78"
                      data-name="icon market research"
                    >
                      <g
                        id="aa72a6cc-799b-4432-a1fe-fe09dd318b05"
                        data-name="Rectangle 5"
                      >
                        <polygon
                          points="109.2 116.09 109.07 27.54 113.68 27.54 123.35 27.52 123.5 130.36 34.95 130.49 34.93 116.2 109.2 116.09"
                          fill="none"
                          stroke="currentColor"
                          stroke-width="3"
                        />
                      </g>
                      <g
                        id="bfb60981-fe2c-4e48-ba4b-49824b088f5b"
                        data-name="Rectangle 4"
                      >
                        <rect
                          x="20.57"
                          y="13.33"
                          width="88.55"
                          height="102.83"
                          transform="translate(-0.1 0.1) rotate(-0.09)"
                          fill="none"
                          stroke="currentColor"
                          stroke-width="3"
                        />
                      </g>
                      <path
                        id="b73c334a-9cb0-46e1-bb66-c1d686f1bd18"
                        data-name="Path 1"
                        d="M39.17,35.2l50.45-.08"
                        fill="none"
                        stroke="currentColor"
                        stroke-width="3"
                      />
                      <path
                        id="af69587f-98a7-4f49-a69e-9f1866dc4eda"
                        data-name="Path 2"
                        d="M39.19,49.48l50.45-.07"
                        fill="none"
                        stroke="currentColor"
                        stroke-width="3"
                      />
                      <path
                        id="a977453a-f370-43d4-a2e5-8ec656b771e9"
                        data-name="Path 3"
                        d="M39.22,63.76l50.44-.07"
                        fill="none"
                        stroke="currentColor"
                        stroke-width="3"
                      />
                      <path
                        id="f0b8c167-147f-4699-910e-daaa7e63153c"
                        data-name="Path 4"
                        d="M39.24,78,89.68,78"
                        fill="none"
                        stroke="currentColor"
                        stroke-width="3"
                      />
                      <path
                        id="f325ef75-aa24-41af-adbb-1aae609adb36"
                        data-name="Path 5"
                        d="M39.26,92.33l38-.06"
                        fill="none"
                        stroke="currentColor"
                        stroke-width="3"
                      />
                    </g>
                  </svg>
                </div>
                <div className="col-10">
                  <p>Market research analysis</p>
                </div>
              </div>
              <div className="row align-items-center">
                <div className="col-2">
                  <svg
                    color="gray"
                    id="b04abcf6-915e-4101-a30d-579f98f82595"
                    data-name="Layer 1"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 144 144"
                  >
                    <g
                      id="a9715317-253e-4e2a-9b8f-e42433a04d67"
                      data-name="icon user flow"
                    >
                      <g
                        id="bbf3f5c3-462d-4c27-ab52-ff2656d3f399"
                        data-name="Ellipse 18"
                      >
                        <circle
                          cx="25.64"
                          cy="70.7"
                          r="11.09"
                          fill="none"
                          stroke="currentColor"
                          stroke-width="3"
                        />
                      </g>
                      <g
                        id="a89ccc62-2133-43c4-9962-3bf96806c48b"
                        data-name="Ellipse 19"
                      >
                        <circle
                          cx="118.36"
                          cy="72.51"
                          r="11.09"
                          fill="none"
                          stroke="currentColor"
                          stroke-width="3"
                        />
                      </g>
                      <path
                        id="a682776c-d2f6-4f16-bbb7-868e47900dc3"
                        data-name="Path 6"
                        d="M36,76.05s17.62,7.16,37.23-6.49,35-3.53,35-3.53"
                        fill="none"
                        stroke="currentColor"
                        stroke-width="3"
                        stroke-dasharray="7.39"
                      />
                    </g>
                  </svg>
                </div>
                <div className="col-10">
                  <p>User Flow and Journey Mapping</p>
                </div>
              </div>
              <div className="row align-items-center">
                <div className="col-2">
                  <svg
                    color="gray"
                    id="ab3c414b-1592-46c3-9697-187b08f2ca7b"
                    data-name="Layer 1"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 144 144"
                  >
                    <g
                      id="e53ffef4-9a0a-4e38-ad1a-ac4841a07600"
                      data-name="icon user personas"
                    >
                      <g
                        id="b1961e76-8879-4870-b58d-fbb871931d3a"
                        data-name="Group 13"
                      >
                        <g
                          id="e4601d93-980f-4b57-b453-9e77c11064eb"
                          data-name="Ellipse 20"
                        >
                          <circle
                            cx="27.08"
                            cy="59.07"
                            r="10.12"
                            fill="none"
                            stroke="currentColor"
                            stroke-width="3"
                          />
                        </g>
                        <path
                          id="b0df7376-c5cd-4ed9-ac41-36e685240ff8"
                          data-name="Path 7"
                          d="M9,93.88S10.75,73.4,28,73.62,45.15,93.83,45.15,93.83"
                          fill="none"
                          stroke="currentColor"
                          stroke-width="3"
                        />
                      </g>
                      <g
                        id="f3807e0f-21fc-4b58-8aeb-8b38c51bb35d"
                        data-name="Group 14"
                      >
                        <g
                          id="e497a404-20ae-490a-94b6-66eb0df81932"
                          data-name="Ellipse 20-2"
                        >
                          <circle
                            cx="72.04"
                            cy="59"
                            r="10.12"
                            fill="none"
                            stroke="currentColor"
                            stroke-width="3"
                          />
                        </g>
                        <path
                          id="ad45f087-cd26-4e49-b257-ce3f3fdb57e8"
                          data-name="Path 7-2"
                          d="M54,93.82S55.7,73.33,73,73.56s17.13,20.2,17.13,20.2"
                          fill="none"
                          stroke="currentColor"
                          stroke-width="3"
                        />
                      </g>
                      <g
                        id="b29fe48d-5542-4aaf-bc62-29409300cd10"
                        data-name="Group 15"
                      >
                        <g
                          id="bcebe444-5129-4297-810b-d9378862cf10"
                          data-name="Ellipse 20-3"
                        >
                          <circle
                            cx="116.99"
                            cy="58.93"
                            r="10.12"
                            fill="none"
                            stroke="currentColor"
                            stroke-width="3"
                          />
                        </g>
                        <path
                          id="b53ee2ae-feed-4966-9caa-cb1e89e5f732"
                          data-name="Path 7-3"
                          d="M98.91,93.75s1.75-20.48,19-20.26,17.12,20.2,17.12,20.2"
                          fill="none"
                          stroke="currentColor"
                          stroke-width="3"
                        />
                      </g>
                    </g>
                  </svg>
                </div>
                <div className="col-10">Persona Research</div>
                <div className="col-2">
                  <svg
                    color="gray"
                    id="ee7e9fed-9daa-45b7-ba4b-6c49211f9444"
                    data-name="Layer 1"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 144 144"
                  >
                    <g
                      id="a0458291-d568-4b73-a2b5-4801893d37ea"
                      data-name="Group 16"
                    >
                      <g
                        id="a8bd378b-6d20-41bc-90fe-0e6b4c1d553e"
                        data-name="Ellipse 20-4"
                      >
                        <circle
                          cx="116.06"
                          cy="60.59"
                          r="9.9"
                          fill="none"
                          stroke="currentColor"
                          stroke-width="3"
                        />
                      </g>
                      <path
                        id="a8f3471d-d9a2-4501-9d43-019f25d5033b"
                        data-name="Path 7-4"
                        d="M98.31,94.64s1.74-20,18.65-19.8,16.73,19.8,16.73,19.8"
                        fill="none"
                        stroke="currentColor"
                        stroke-width="3"
                      />
                    </g>
                    <g
                      id="e02afa2a-58d7-4750-8600-bedf5c8f43c1"
                      data-name="Group 17"
                    >
                      <g
                        id="a238f9a9-5e1b-4d3d-9a86-ff6c1cb1bfa3"
                        data-name="Ellipse 20-5"
                      >
                        <circle
                          cx="28.05"
                          cy="60.59"
                          r="9.9"
                          fill="none"
                          stroke="currentColor"
                          stroke-width="3"
                        />
                      </g>
                      <path
                        id="ae8de4eb-f6c8-4d3e-9cc4-ca6a2ce2a09c"
                        data-name="Path 7-5"
                        d="M10.31,94.64s1.74-20,18.64-19.8,16.74,19.8,16.74,19.8"
                        fill="none"
                        stroke="currentColor"
                        stroke-width="3"
                      />
                    </g>
                    <g
                      id="b205e476-3371-4b77-8abe-8d14ec116b03"
                      data-name="Rectangle 6"
                    >
                      <path
                        d="M57.63,52.36v5h-5v-5h5m3-3h-11v11h11v-11Z"
                        fill="currentColor"
                      />
                    </g>
                    <g
                      id="e4da75a9-ae96-411b-873b-98441f0b339c"
                      data-name="Rectangle 11"
                    >
                      <path
                        d="M57.63,74.36v5h-5v-5h5m3-3h-11v11h11v-11Z"
                        fill="currentColor"
                      />
                    </g>
                    <g
                      id="a7f70bc5-7b12-464f-bce1-55e3b0396456"
                      data-name="Rectangle 7"
                    >
                      <path
                        d="M73,52.36v5H68v-5h5m3-3H65v11H76v-11Z"
                        fill="currentColor"
                      />
                    </g>
                    <g
                      id="b3328c6d-08e0-4352-a939-cac74a4aecb0"
                      data-name="Rectangle 10"
                    >
                      <path
                        d="M73,67.76v5H68v-5h5m3-3H65v11H76v-11Z"
                        fill="currentColor"
                      />
                    </g>
                    <g
                      id="b85501a4-f473-4f97-a14a-89b890fff3b6"
                      data-name="Rectangle 8"
                    >
                      <path
                        d="M88.43,52.36v5h-5v-5h5m3-3h-11v11h11v-11Z"
                        fill="currentColor"
                      />
                    </g>
                    <g
                      id="b5654b08-21dc-44a5-a1c2-175bae3051a0"
                      data-name="Rectangle 9"
                    >
                      <path
                        d="M88.43,67.76v5h-5v-5h5m3-3h-11v11h11v-11Z"
                        fill="currentColor"
                      />
                    </g>
                    <g
                      id="bccf4931-d6f8-476d-87b3-09e8b65abd43"
                      data-name="Rectangle 12"
                    >
                      <path
                        d="M88.43,83.16v5h-5v-5h5m3-3h-11v11h11v-11Z"
                        fill="currentColor"
                      />
                    </g>
                  </svg>
                </div>
                <div className="col-10">
                  <p>Design Workshops</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
);

const SiteMap = () => (
  <section className="my-6">
    <div className="container">
      <div className="row">
        <div className="col-12">
          <StaticImage
            style={{ width: "100%" }}
            src="../../images/portfolio/bundleloan/sitemap.png"
            alt="Bundleloan Sitemap"
          />
        </div>
      </div>
    </div>
  </section>
);

const FocusAreasAndNeeds = () => (
  <section className="my-6">
    <div className="container">
      <div className="row">
        <div className="col-12 col-lg-6 pr-lg-4">
          <h2>Key Focus Areas</h2>
          <p>What are the challenges or barriers to use in the industry?</p>
          <p>How can and should the persona types interact?</p>
          <p>
            How can we give the user explicit control over information sharing
            with personas?
          </p>
          <p>
            What part of the mortgage buying process is the most confusing for
            users?
          </p>
          <p>
            How can we quickly guide users through the process and educate them
            at the same time?
          </p>
        </div>
        <div className="col-12 col-lg-6 pl-lg-6">
          <h2>Core Client Needs</h2>
          <div className="row">
            <div className="col-12 col-lg-6 mb-5">
              <p>
                <b>Privacy</b>
              </p>
              <p>
                Protecting users’ personal information was of paramount
                importance.
              </p>
            </div>
            <div className="col-12 col-lg-6">
              <p>
                <b>One-stop-shop</b>
              </p>
              <p>
                Bundle Loans needed a user experience consultant that offered a
                full set of services.
              </p>
            </div>
            <div className="col-12 col-lg-6 mt-5 mt-lg-0">
              <p>
                <b>Cause</b>
              </p>
              <p>
                The customer needed us to believe in their mission of making it
                easier to get a mortgage.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
);

const Wireframes = () => (
  <section className="my-6">
    <div className="container">
      <div className="row">
        <div className="col-12">
          <StaticImage
            style={{ width: "100%" }}
            src="../../images/portfolio/bundleloan/wireframes.png"
            alt="Bundleloan Wireframes"
          />
        </div>
      </div>
    </div>
  </section>
);

const ProjectForm = ({location}) => (
  <section className="bg-light py-6">
    <div className="container">
      <div className="row">
        <div className="col-12 col-lg-6">
          <h2>Have a Project?</h2>
          <p className="pr-5">We'd love to hear about it!</p>
          <small className="text-muted pr-5">
            Email us at{" "}
            <a href="mailto:solicitations@729solutions.com">
              solicitations@729solutions.com
            </a>{" "}
            for solicitations regarding partnerships, linkbacks, or
            subcontracting opportunities.
          </small>
        </div>
        <div className="col-12 col-lg-6">
          <ContactForm showHowYouFindUs={false} buttonText="Submit" location={location} />
        </div>
      </div>
    </div>
  </section>
);

const WhatWeLearned = () => (
  <section className="my-6">
    <div className="container">
      <div className="row">
        <div className="col-12 col-lg-6">
          <h2>
            IN CLOSE COLLABORATION WITH BUNDLE LOANS, 729 SOLUTIONS USED
            WIREFRAMES, USER FLOWS AND PERSONAS TO DEVELOP A SOLUTION THAT
            SUPPORTS A SIMPLE, EASY USER EXPERIENCE.
          </h2>
        </div>
        <div className="col-12 col-lg-6">
          <h2>What we learned</h2>
          <p>
            This project ended up being much larger and more complex than any of
            us could have anticipated. New personas came out of the woodwork and
            we suddenly needed to account for new user journey scenarios.
          </p>
          <p>
            From a technical standpoint, this project was an opportunity for the
            development team to integrate with Stripe to implement recurring
            monthly subscription payments on the site. It was also a chance for
            the team to expand on their experience with React to satisfy UI/UX
            requirements.
          </p>
          <p>
            It was of the utmost importance to take all of the technical details
            and behind-the-scenes UI/UX work and boil it all down into a simple
            user experience. By working closely with the client, 729 Solutions
            made this happen and the client was thrilled with the results.
          </p>
        </div>
      </div>
    </div>
  </section>
);

const Mockups = () => (
  <section className="my-6">
    <div className="container">
      <div className="row">
        <div className="col-12">
          <StaticImage
            style={{ width: "100%" }}
            src="../../images/portfolio/bundleloan/mockups.png"
            alt="Bundleloan Mockups"
          />
        </div>
      </div>
    </div>
  </section>
);

const BundleQuote = () => (
  <section className="my-6 bg-dark">
    <div className="container">
      <div className="row align-items-center p-5">
        <div className="col-12 col-lg-6">
          <Quote
            dark
            quote="We started working with 729 at the earliest stages of Bundle and the team was integral in our Minimum Viable Product release. The design team did an awesome job defining granular user personas and created the foundation for the look and feel of our brand."
            name="Eric Mager"
            company="CEO"
          />
        </div>
        <div className="col-12 col-lg-6">
          <div className="p-6">
            <StaticImage
              style={{ width: "100%" }}
              src="../../images/portfolio/bundleloan/logo.png"
            />
          </div>
        </div>
      </div>
    </div>
  </section>
);

const Index = ({location}) => {
  return (
    <Layout>
      <Seo
        pathName={location.pathname}
        title="Bundle Loan"
        description="We helped Bundle loan turn an idea into reality by building an easy-to-use website that brings clairity to the mortgage purchasing process."
      />
      <PortfolioHeader />
      <Sketches />
      <ProjectGoals />
      <BundleLogos />
      <HowWeDidIt />
      <SiteMap />
      <FocusAreasAndNeeds />
      <Wireframes />
      <WhatWeLearned />
      <Mockups />
      <BundleQuote />
      <ProjectForm location={location} />
      <PortfolioFeed className="my-6" />
    </Layout>
  );
};

export default Index;
